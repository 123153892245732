import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateService } from 'outshared-lib';

@Injectable({
    providedIn: 'root'
})
export class MinimumAgeValidator {
    constructor(
        private dateService: DateService
    ) { }

    public validate(date: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value == null) {
                return null;
            }

            if (!this.dateService.isValid(control.value)) {
                return null;
            }

            const FORMAT_DATE = 'YYYY-MM-DD';
            return this.dateService.isBefore(control.value, date) ? null : {
                'date-minimum': {
                    'date-minimum': this.dateService.format(date, FORMAT_DATE),
                    'actual': this.dateService.format(control.value)
                }
            };
        };
    }
}

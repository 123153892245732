import { AbstractControl, ValidatorFn } from '@angular/forms';

export class PasswordStrengthValidator {
    static hasRequiredCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const match = value.match(/[@.\-?+!#&=]/g);
            return match ? null : { requiredCharacters: { value: control.value } };
        };
    }

    static hasInvalidCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const match = value.match(/[^a-zA-Z0-9@.\-?+!#&=]+/g);
            return match ? { invalidCharacters: { value: control.value } } : null;
        };
    }

    static hasNoWordCharacters(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const match = value.match(/([A-Z]{2})/gi);
            return match ? null : { characters: { value: control.value } };
        };
    }

    static hasNoNumbers(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const match = value.match(/([0-9])/g);
            return match ? null : { invalidNumbers: { value: control.value } };
        };
    }

    static isBetween(min: number, max: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value ? control.value : '';
            const currentLength = value.trim().length;
            return currentLength < min || currentLength > max ? { currentLength: { value: currentLength } } : null;
        };
    }
}

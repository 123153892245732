import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const ValidatePhonenumber: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
        return null;
    }

    const regex = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$)/;
    const phoneValid = regex.test(control.value);

    if (!phoneValid) {
        return { phoneInvalid: true };
    }

    return null;
};

import { AbstractControl, ValidatorFn } from '@angular/forms';

export function equalFormControlValidator(firstControl: string, secondControl: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: unknown } | null => {
        const equal = control.get(firstControl)?.value === control.get(secondControl)?.value;

        return !equal
            ? {
                  notEqual: {
                      value: control.value,
                  },
              }
            : null;
    };
}
